import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import {FaBars} from "react-icons/fa"
function FooterNavbar() {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
    <Navbar style={{display:"flex", flexDirection:"row", justifyContent:"center", color: "white", position: "relative"}}>
      <NavbarToggler onClick={toggleNavbar} style={{color:"black", direction:"down"}}><FaBars/> </NavbarToggler>
      <Collapse style={{
                      backgroundColor:"rgba(0,0,0,0.7)",
                      zIndex:"9999",
                      borderRadius:"10px",
                      position: "absolute",
                      top: "100%", // Despliega el menú justo debajo del botón
                      left: "50%",
                      transform: "translateX(-50%)", // Centra el menú debajo del botón
                      minWidth: "140px" 
                      }}
                 isOpen={!collapsed} navbar>
        <Nav navbar style={{margin:"0"}}>
          <NavItem >
            <NavLink style={{
                              color:"white",
                              fontSize:"15px",
                              fontWeight:"400",
                              paddingLeft:"10px"

                              }} 
                      href="/">Inicio</NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{color:"white",fontSize:"15px",
                              fontWeight:"400", paddingLeft:"10px"}} href="/amenities">
              Amenities
              </NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{color:"white",fontSize:"15px",
                              fontWeight:"400", paddingLeft:"10px"}} href="/barrios">
              Barrios
              </NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{color:"white",fontSize:"15px",
                              fontWeight:"400", paddingLeft:"10px"}} href="/avances">
              Avances
              </NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{color:"white",fontSize:"15px",
                              fontWeight:"400", paddingLeft:"10px"}} href="/energiasustentable">
              Energia Sustentable
              </NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{color:"white",fontSize:"15px",
                              fontWeight:"400", paddingLeft:"10px"}}href="/conectividad">
              Conectividad
              </NavLink>
              
          </NavItem>
          <NavItem>
            <NavLink style={{color:"white",fontSize:"15px",
                              fontWeight:"400", paddingLeft:"10px"}}href="/galeria">
              Galería
              </NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  </div>
);
}

export default FooterNavbar;