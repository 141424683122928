import React from "react"
import Clarin from "../media/clarin.png"
import LaNacion from "../media/lanacion.png"
import Noticias from "../media/noticias.png"
import ElCronista from "../media/elcronista.jpg"
import Infobae from "../media/infobae.png"
import Forbes from "../media/forbes.png"
import "./components.css"
import Slider from "react-slick"


export const NewsPapersLogos = () => {
    const settings = {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              },
            },
          ],
          autoplay: true,
          autoplaySpeed: 2000,   
          speed: 500,

      };
const items = [
    {
        key: "1",
        src: ElCronista,
        alt: "El cronista"
    },
    {       
        key: "2",
        src: Clarin,
        alt: "Clarin"
    },
    {       
        key: "3",
        src: LaNacion,
        alt: "La nacion"
    },
    {       
        key: "4",
        src: Infobae,
        alt: "Infobae"
    },
    {       
        key: "5",
        src: Noticias,
        alt: "Noticias"
    },
    {       
        key: "6",
        src: Forbes,
        alt: "Forbes"
    }

];
    return(
        <div className="newsPapersLogos-div">
            <Slider {...settings}>
                {items.map((item)=>(
                    <div key={item.key} className="newsPapers-imgDiv">
                    <img key={item.key} className="newsPapersLogos-img" src={item.src} alt={item.alt}/>
                    </div>
                    ))}
            </Slider>
        </div>
    )
}