import React from 'react';
  import "./header.css";
import { Link } from 'react-router-dom';


export const NavBar2 = () => {


return (
      <>
        <nav>
                        <ul className='navBar-navs nav2'>
              <li><Link to="/" className='navBar2-linkItem'>Inicio</Link></li>
              <li><Link to="/amenities" className='navBar2-linkItem'>Amenities</Link></li>
              <li><Link to="/barrios" className='navBar2-linkItem'>Barrios</Link></li>
              <li><Link to="/avances" className='navBar2-linkItem'>Avances</Link></li>
              <li><Link to="/energiasustentable" className='navBar2-linkItem'>Energia Sustentable</Link></li>
              <li><Link to="/conectividad" className='navBar2-linkItem'>Conectividad</Link></li>
              <li><Link to="/galeria" className='navBar2-linkItem'>Galería</Link></li>
            </ul>
        </nav>
      </>












  //   <div>
  //   <Navbar >
  //       <Nav className='navBar-navs nav2' navbar>
  //         <NavItem>
  //           <NavLink className='navBar2-linkItem' href="">
  //             Inicio
  //           </NavLink>
  //         </NavItem>
  //         <NavItem>
  //           <NavLink className='navBar2-linkItem' href="">
  //             Amenities
  //           </NavLink>
  //         </NavItem>
  //         <NavItem>
  //           <NavLink className='navBar2-linkItem' href="">
  //             Barrios
  //           </NavLink>
  //         </NavItem>
  //         <NavItem>
  //           <NavLink className='navBar2-linkItem' href="">
  //             Avances
  //           </NavLink>
  //         </NavItem>
  //         <NavItem>
  //           <NavLink className='navBar2-linkItem' href="">
  //             Energia Sustentable
  //           </NavLink>
  //         </NavItem>
  //         <NavItem>
  //           <NavLink className='navBar2-linkItem' href="">
  //             Conectividad
  //           </NavLink>
  //         </NavItem>
  //         <NavItem>
  //           <NavLink className='navBar2-linkItem' href="">
  //             Galería
  //           </NavLink>
  //         </NavItem>
          
  //       </Nav>
  //   </Navbar>
  // </div>
)}