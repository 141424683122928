import React, { useEffect, useRef } from "react";
import "./components.css"
import { Button } from "reactstrap";


export const ImageTextComponent = ({title, text, src, buttonText, href}) => {
    const animatedElementsRef = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                    observer.unobserve(entry.target);
                }
            });
        });

        animatedElementsRef.current.forEach(element => {
            if (element) {
                observer.observe(element);
            }
        });

        return () => {
            animatedElementsRef.current.forEach(element => {
                if (element) {
                    observer.unobserve(element);
                }
            });
        };
    }, []);

    return (
    <div className="imageText-Container">

        <div className="imageText-textContainer" ref={el => animatedElementsRef.current.push(el)}>
            <h1 className="imageText-h1">{title}</h1>
            <h2 className="imageText-h2">{text}</h2>
            {buttonText && (
            <Button outline className="imagetext-button" href={href}>{buttonText}</Button>
          )}
        </div>

        <div className="imageText-imageContainer">
            <img className="imageText-image" alt={title} src={src} ref={el => animatedElementsRef.current.push(el)}></img>
        </div>


    </div>
    )

}