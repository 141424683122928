import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Card, CardImg, CardText, CardTitle, Col, ListGroup, ListGroupItem } from "reactstrap";
import GonzaloPablo from "../media/gonzaloYPablo.jpeg"
import Federico from "../media/federico.jpeg"
import Soledad from "../media/soledad.jpeg"
import ValeriaYFran from "../media/valeriaYFran.webp"
import 'bootstrap/dist/css/bootstrap.min.css';
import { IoIosArrowBack,IoIosArrowForward } from "react-icons/io";
import "./components.css"


const Reviews1= () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: true,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
              },
            },
          ],
          autoplay: true,
          autoplaySpeed: 2000,   
          speed: 500,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
      };

  const items = [
    {
    key: 1,
      src: GonzaloPablo,
      name: 'Gonzalo y Pablo',
      text: '"Gracias y más al equipo de ventas porque nos ayudaron con todo. Es nuestro primer  terreno y luego de mucho buscar, terminamos acá por recomendación. Excelente desarrollo, fácil acceso y todos los servicios".					',
    },
    {
      key: 2,
      src: Soledad,
      name: 'Soledad',
      text: '"Me encanto la propuesta de Barrio Eco Sustentable, la oferta de terrenos es amplia y tiene una muy buena ubicación y acceso. No pensé que tuviera tantos amenities, me sorprendió".					',
    },
    {
      key: 3,
      src: ValeriaYFran,
      name: 'Valeria & Fran',
      text: '"Gracias y más al equipo de ventas porque nos ayudaron con todo. Es nuestro primer  terreno y luego de mucho buscar, terminamos acá por recomendación. Excelente desarrollo, fácil acceso y todos los servicios".					',
    },
    {
      key: 4,
      src: Federico,
      name: 'Federico',
      text: '"Quedé encantado con el barrio, el concepto es diferente a otros desarrollos que visité. Arrancó como una inversión, pero definitivamente voy a mudarme en cuanto esté listo para construir".					',
    },
  ];

    return (
        <div style={{ width: "90%", margin: "auto", paddingTop: "50px"}}>
          <Slider {...settings}>
                  {items.map((item, index) => (
                    <div key={index}  style={{padding:"5px"}}>
                {/* //  <Col key={index} style={{padding:"5px", display: "flex", flexDirection:"row"}} > */}
                     <div className="reviews-Size">
                     <ListGroup flush>
                     <ListGroupItem style={{display: "flex", flexDirection:"row", height:"80px", margin:"5px", padding:"0"}}>
                        <CardImg top style={{margin:"5px", width:"70px", height:"70px", borderRadius: "50%"}} src={item.src} alt={item.title} />                        
                        <CardTitle style={{height:"70px", fontWeight:"500", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>{item.name}</CardTitle>
                         </ListGroupItem>
                         <ListGroupItem>
                             <CardText style={{fontSize: "13px", fontStyle:"italic", height:"auto", display:"flex", flexDirection:"row", justifyContent:"center"}}>{item.text}</CardText>
                         </ListGroupItem>
                         </ListGroup>

                     </div>
                     </div>
                //  </Col>
                 ))            
                 }
          </Slider>
        </div>
      );
};
// Custom next arrow component
function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div
        style={{
          display: "block",
          borderRadius: "50%",
          padding: "10px",
          position: "absolute",
          top: "50%",
          right: "-25px",
          transform: "translateY(-50%)",
          cursor: "pointer",
          zIndex: 2
        }}
        onClick={onClick}
      >
        <IoIosArrowForward style={{ color: "grey", fontSize: "20px" }} />
      </div>
    );
  }
  
  // Custom prev arrow component
  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        style={{
          display: "block",
          borderRadius: "50%",
          padding: "10px",
          position: "absolute",
          top: "50%",
          left: "-30px",
          transform: "translateY(-50%)",
          cursor: "pointer",
          zIndex: 2
        }}
        onClick={onClick}
      >
        <IoIosArrowBack style={{ color: "grey", fontSize: "20px" }} />
      </div>
    );
}

export default Reviews1;

