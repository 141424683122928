import React, { useRef, useState } from "react";
import Video from "../media/3PINOSNUEVOALTA.mp4";
import Header from "../components/header/header";
import "../components/hero/hero.css"
import Reviews1 from "../components/reviews1";
import { NewsPapersLogos } from "../components/newsPapersLogos";
import Footer from "../components/footer/footer";

export const VideoInstitucional = () => {



  return (
    <div className="fistContainer">
      <div class="video-background">
        <video autoPlay loop class="video-bg">
           <source src={Video} type="video/mp4"/>
    Tu navegador no soporta el elemento de video.
        </video>
        <div class="content-overlay">
          <Header backgroundColor= "rgba(0, 0, 0, 0.2)" />
        </div>
      </div>
      <Reviews1/>
            <NewsPapersLogos/>
            <Footer/>  
  </div>
);
}

export default VideoInstitucional;