import React from "react";
import { Header } from "../components/header/header";
import AvancesImg1 from "../media/avancesImg1.jpg";
import AvancesImg2 from "../media/avancesImg2.jpeg";
import AvancesImg3 from "../media/amenitiesHero2.png"
import { TextImageComponent } from "../components/textImageComponent";
import { ImageTextComponent } from "../components/imageTextComponent";
import Reviews1 from "../components/reviews1";
import { NewsPapersLogos } from "../components/newsPapersLogos";
import Footer from "../components/footer/footer";

export const Avances = () => {



    return (
            <div className="firstContainer">
            
            <Header backgroundColor="rgba(37,37,37,1)"/>
            <TextImageComponent 
                title="INAUGURAMOS LA CASONA"
                text="Hemos inaugurado la casona!! Luego de una intensa labor cuyo objetivo fue la puesta en valor del antiguo casco respetando la arquitectura original. Los interiores mantienen la esencia de la construcción de antaño con una cuidada ambientación para modernizar cada uno de los espacios. La confitería ofrece una importante cava cuenta con diferentes espacios interiores y exteriores que invitan a disfrutar de un lugar único rodeado de amplios espacios verdes. Te invitamos a un recorrido 360 de la casona"
                buttonText="VER AVANCES"
                href=""
                src={AvancesImg1}/>
            <ImageTextComponent
                title= "SEGUNDA ETAPA"
                text= "A partir de una nueva ampliación, en su segunda etapa el desarrollo contará con 3851 lotes, dos lagunas, 12 barrios. Nuevos espacios comunes, comerciales y áreas verdes en entorno natural. La superficie total de este mega desarrollo sustentable asciende de esta manera a 836 hectareas. Contará en esta etapa con dos accesos sobre la autovía 6 -a 10 km de distancia del Acceso Oeste. Ya se encuentran a la preventa dos de los nuevos barrios."
                buttonText= "MASTERPLAN ETAPA 2"
                href=""
                src={AvancesImg2}
            />
            <TextImageComponent 
                title="NUEVA PISCINA"
                text="Como novedad de la temporada de verano 2023 se inauguró una piscina semiolímpica con playa húmeda, próxima a las áreas comunes, siendo la segunda pileta disponible para copropietarios de Tres Pinos Villa de Campo."
                src={AvancesImg3}
                />

            <Reviews1/>
            <NewsPapersLogos/>
            <Footer/>  

            </div>


    )
}
export default Avances;