import React, { useEffect, useState } from "react";
import ImgHero from "../media/ventaLotesHero.png"
import Grace from "../media/GraceCabanillas.png"
import Img1 from "../media/ventaLotesImg.png"
import logo from "../media/logo1.png"
import "./views.css"
import { Link } from "react-router-dom";
import Reviews1 from "../components/reviews1";
import { NewsPapersLogos } from "../components/newsPapersLogos";
import Footer from "../components/footer/footer";

export const VentaLotes = () => {

    const [visible, setVisible] = useState(true);

    useEffect(() => {
      const interval = setInterval(() => {
        setVisible((prevVisible) => !prevVisible);
      }, 1000); // Cambia cada 1 segundo (1000 ms)
  
      return () => clearInterval(interval);
    }, []);


    return (
        <div className="firstContainer">
            <div className="heroVentas" style={{ backgroundImage: `url(${ImgHero})` }}>
                <img src= {logo} alt="logo"></img>
                <p className="hero-p">Ganar en calidad de <span className={`fade-text ${visible ? 'visible' : 'hidden'} cursiva` }>vida</span><br/>
                es posible y accesible.</p>

                <button className="hero-button"><Link to= "/video-institucional" className="hero-link">VER VIDEO</Link></button>
            </div>
            <div className="imgText">
                <div className="img-div">
                    <p>La <span className={`fade-text ${visible ? 'visible' : 'hidden'} cursiva` }>experiencia</span> <br/>
                        de vivir en armonía</p>
                </div>
                <div className="text-div">
                <h5 className="text-p">¡Inspirá naturaleza! Tres Pinos Villa de Campo acompaña una 
                    elección de vida que reúne características únicas, entorno verde, conectividad y
                     sostenibilidad.
                 </h5>

                <p className="text-p">Ubicado en General Rodriguez, sobre ruta 6, KM 142 a 10km del Acceso Oeste, todo 
                    sobre autopista. A pocos minutos de la ciudad de Buenos Aires.
                </p>

                <p className="text-p">El desarrollo brinda un entorno de 836 hectáreas junto con diversos amenities y 
                    áreas verdes. El proyecto cuenta con 13 barrios cerrados donde se podrán disfrutar 
                    de dos lagunas para deportes acuáticos sin motor, cancha de polo, predio de golf, 
                    SUM y dos piscinas.
                </p>
                </div>
            </div>

            <div className="imgText">
                <div className="img-div">
                    <img src={Grace} className="img-img" alt="Grace"></img>
                </div>
                <div className="text-gracediv">
                <p className="text-p-sm">Gerente de ventas de MJM Inversiones
                 </p>

                <h className="text-p-grace"><span className={`fade-text ${visible ? 'visible' : 'hidden'} cursiva` } style={{fontSize:"50px"}}>Grace </span>Cabanillas</h>

                <p className="text-p">“Observamos que cada vez más personas buscan un cambio en su 
                    calidad de vida y priorizan los entornos naturales, sostenibles y conectados. 
                    Este desarrollo crece para acompañar el movimiento natural de la gente desde la 
                    ciudad hacia el verde, la naturaleza y los espacios abiertos”
                </p>
                </div>
            </div>
            <div className="map-div" style={{backgroundImage: `url(${Img1})`}}>
                <div className="map-div-1">
                    <div style={{paddingTop:"50px"}}>
                        <h5 className="text-p-sm">Proyecto
                        </h5>
                        <h3 className="h3-map">Tres Pinos<br/>
                        <span className={`fade-text ${visible ? 'visible' : 'hidden'} cursiva` }>Villa de Campo</span>
                        </h3>
                    </div>
                </div>
                <div className="map-div-2">
                </div>
            </div>
            <div className="last-div">
                <h5 className="last-div-h5">Comprá tu lote con financiación directa, adelanto + cuotas.</h5>
                <h5 className="last-div-h5-2">Sin intermediarios.</h5>
                <h5 className="last-div-h5-3">Lotes desde mil metros cuadrados. Y podés hacer uso de los amenities desde la firma del boleto.</h5>
                <button className="hero-button"><Link to= "/video-institucional" className="hero-link">VER VIDEO</Link></button>

            </div> 
        </div>
    )
}

export default VentaLotes;