import React from 'react';
  import "./header.css";
import { Link } from 'react-router-dom';


export const NavBar = () => {


return (
  <nav>
    <ul className='navBar-navs navBar-Menu-1' style={{justifyContent:"end"}}>
      <li className='navBar1-linkItem'><Link to="/ventalotes" className='navBar1-a'>Venta de Lotes</Link></li>
      <li className='navBar1-linkItem'><Link to="/prensa" className='navBar1-a'>Prensa</Link></li>
      <li className='navBar1-linkItem'><a href="https://mls.kuu.la/share/collection/7JYsR?fs=1&vr=1&sd=1&initload=0&thumbs=1" className='navBar1-a'>Ir al Tour 360</a></li>
      <li className='navBar1-linkItem'><a href="https://kuula.co/share/collection/7Xqff?logo=0&info=0&fs=1&vr=1&sd=1&initload=0&thumbs=1" className='navBar1-a'>La Casona</a></li>
      <li className='navBar1-linkItem'><Link to="/video-institucional" className='navBar1-a'>Ver Video</Link></li>
    </ul>
  </nav>

)}