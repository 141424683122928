import React from "react";
import Hero from "../components/hero/hero";
import { TextImageComponent } from "../components/textImageComponent";
import { ImageTextComponent } from "../components/imageTextComponent";
import amenitiesHero1 from "../media/amenitiesHero1.jpg"
import amenitiesHero2 from "../media/amenitiesHero2.png"
import amenitiesHero3 from "../media/amenitiesHero3.png"
import amenitiesHero4 from "../media/amenitiesHero4.png"
import amenitiesHero5 from "../media/amenitiesHero5.png"
import amenitiesHero6 from "../media/amenitiesHero6.png"
import amenitiesHero7 from "../media/amenitiesHero7.jpg"
import amenitiesHero8 from "../media/amenitiesHero8.png"
import amenitiesImg1 from "../media/amenitiesImg1.jpeg"
import amenitiesImg2 from "../media/amenitiesImg2.jpg"
import Reviews1 from "../components/reviews1";
import { NewsPapersLogos } from "../components/newsPapersLogos";
import Footer from "../components/footer/footer";

export const Amenities = () =>{

    const items = [
        {
            src: amenitiesHero1,
            text1: "Dos",
            text2: "Lagunas",
            description: "Este mega desarrollo contará con una imponente laguna de 50 hectáreas y una segunda laguna de menor tamaño, ambas con playas con acceso a los copropietarios y para actividades de recreación y deportes naúticos sin motor ",
            key: 1
        },
        {
            src: amenitiesHero2,
            text1: "Dos piscinas",
            text2: "y espacios de recreación",
            description: "Dos piscinas, una de ellas semiolímpica. Junto a la zona de pérgolas, SUM y parrillas, se encuentra disponible un espacio  juegos para los niños y gimnasio al aire libre.",
            key: 2
        },
        {
            src: amenitiesHero3,
            text1: "Confiteria &",
            text2: "Cowork",
            description: "Entre las áreas comunes se ha realizado la puesta en valor del antiguo casco y proximamente se inaugura una confitería con cava para degustaciones.  Contará en la parte superior con un espacio de coworking",
            key: 3
        },
        {
            src: amenitiesHero4,
            text1: "Área",
            text2: "Deportiva",
            description: "Un mega desarrollo único en Zona Oeste, ubicado en General Rodriguez. Acompañamos una elección de vida que reúne características únicas, entorno verde con imponente laguna, conectividad y sustentabilidad.",
            key: 4
        },
        {
            src: amenitiesHero5,
            text1: "Colegio",
            text2: "Nicolás Tesla",
            description: "A partir del año 2024, comienzan las clases en el nuevo Colegio Nikolas Tesla, ubicado en TRES PINOS. Este innovador centro educativo ofrecerá una educación integral desde el nivel jardín de infantes hasta la secundaria.",
            key: 5
        },
        {
            src: amenitiesHero6,
            text1: "Canchas",
            text2: "de Golf & Polo",
            description: " Podrás desarrollar habilidades de conducción en nuestras instalaciones dedicadas al 'Driving' y tener un espacio único destinado para caballos, perfecto para el cuidado, entrenamiento y disfrute de estos magníficos animales.                                                   ",
            key: 6
        },
        {
            src: amenitiesHero7,
            text1: "Área",
            text2: "Comercial",
            description: "Sobre la ruta al lado de la estación de servicio que esta haciendo la misma desarrolladora. El desarrollo contará en el ingreso por la ruta 6 un espacio destinado a una estación de servicio, un supermercado, y 60 locales.",
            key: 7
        },
        {
            src: amenitiesHero8,
            text1: "Huerta  ",
            text2: "Hidrodinámica",
            description: "Huerta Bio dinámica con una compostera. Laguna con playa pública,ideal para disfrutar de días soleados y actividades acuáticas. Colegio Nikola Tesla y una exclusiva cancha de Golf.                                                                                                  ",
            key: 8
        }
    ]

    return(
        <div className="firstContainer">
            <Hero items = {items} headerBackground = "rgba(0, 0, 0, 0.2)"/>
            <TextImageComponent 
                title="MEGA DESARROLLO ÚNICO" 
                text="Ubicado en General Rodríguez, se trata de un mega desarrollo sustentable que contará con 836 hectáreas, 3851 lotes, dos lagunas, 12 barrios cerrados con espacios comunes, comerciales y áreas verdes en entorno natural. El proyecto presenta abundantes espacios verdes con variadas especies, junto al SUM y piscina se encuentran pérgolas, biciletas y un sector de parrillas. La segunda pileta es semiolímpica con playa húmeda. Un antiguo casco remodelado será confitería y espacio de cowork. El desarrollo contará con un sector comercial y áreas deportivas. Las dos lagunas contarán con muelle y playa para deportes náuticos sin motor." 
                src={amenitiesImg1}/>
            <ImageTextComponent                
                title="¡HEMOS INAUGURADO LA CASONA!" 
                text="Luego de una intensa labor cuyo objetivo fue la puesta en valor del antiguo casco respetando la arquitectura original. Los interiores mantienen la esencia de la construcción de antaño con una cuidada ambientación para modernizar cada uno de los espacios." 
                src={amenitiesImg2}/>
            <TextImageComponent 
                title="ÁREA COMERCIAL" 
                text="Sobre la ruta al lado de la estación de servicio que esta haciendo la misma desarrolladora. El desarrollo contará en el ingreso por la ruta 6 un espacio destinado a una estación de servicio, un supermercado, y 60 locales." 
                src={amenitiesHero7}/>
            <ImageTextComponent 
                title="COLEGIO NICOLÁS TESLA" 
                text="PROYECTO APERTURA. El futuro Colegio Nikola Tesla presenta una propuesta educativa laica - plural e integrada - con sentido transversal a todas las edades, de jornada completa con Inglés intensivo. Contará con deporte y ecología, producción de lenguaje tecnológico, digital y artístico. El colegio tendrá los niveles inicial, primario y secundario." 
                src={amenitiesHero5}/>
            <TextImageComponent 
                title="DESARROLLO SUSTENTABLE" 
                text="El proyecto se destaca por desplegar un plan integral de sustentabilidad y autonomía energética con impacto en las emisiones ambientales, en los costos de construcción y mantenimiento y en el acceso a la vivienda. Asimismo contará con una huerta biodinámica." 
                src={amenitiesHero8}/>
            <Reviews1/>
            <NewsPapersLogos/>
            <Footer/>  
        </div>
    )




}
export default Amenities;