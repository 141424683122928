import React from "react";
import { Header } from "../components/header/header";
import nota1 from "../media/nota1.jpg"
import nota2 from "../media/nota2.jpg"
import nota7 from "../media/nota2.jpg.webp"
import nota3 from "../media/nota3.jpg"
import nota4 from "../media/nota4.jpg"
import nota5 from "../media/nota5.jpg"
import nota6 from "../media/nota6.jpg"
import "./views.css"
import Reviews1 from "../components/reviews1";
import { NewsPapersLogos } from "../components/newsPapersLogos";
import Footer from "../components/footer/footer";



export const Prensa = () =>{


    return(
        <div className="firstContainer">
            <Header backgroundColor="rgba(37,37,37,1)"/>
            <h3 className="prensa-h3">PRENSA</h3>
            <div className="prensa-div-1">
                <div className="prensa-div-img"><img className="prensa-img" src={nota1} alt="1"></img></div>
                <div className="prensa-div-img"><img className="prensa-img" src={nota2} alt="2"></img></div> 
                <div className="prensa-div-img"><img className="prensa-img" src={nota3} alt="3"></img></div>
            </div>
            <div className="prensa-div-1">
                <div className="prensa-div-img"><img className="prensa-img" src={nota4} alt="4"></img></div>
                <div className="prensa-div-img"><img className="prensa-img" src={nota5} alt="5"></img></div> 
                <div className="prensa-div-img"><img className="prensa-img" src={nota6} alt="6"></img></div>
            </div>
            <div className="prensa-div-1">
                <div className="prensa-div-img" style={{flex:"0.33"}}><img className="prensa-img" src={nota7} alt="7"></img></div>
                
            </div>
            <Reviews1/>
            <NewsPapersLogos/>
            <Footer/>  

        </div>
    )
}
export default Prensa;