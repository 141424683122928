import React from "react";
import ConectividadHero from "../media/homeHeroImg5.jpg"
import LogoHumac from "../media/humanblanco1.png"
import Hero from "../components/hero/hero";
import { TextImageComponent } from "../components/textImageComponent";
import { ImageTextComponent } from "../components/imageTextComponent";
import ConectividadImg1 from "../media/conectividadImg1.jpeg"
import ConectividadImg2 from "../media/conectividadImg2.png"
import ConectividadImg3 from "../media/conectividadImg3.jpeg"
import Reviews1 from "../components/reviews1";
import { NewsPapersLogos } from "../components/newsPapersLogos";
import Footer from "../components/footer/footer";


export const Conectividad = () => {
    const items = [
        {
            src: ConectividadHero,
            logo: LogoHumac
        }
    ]


    return(
        <div className="firstContainer">
            <Hero items = {items} headerBackground = "rgba(0, 0, 0, 0.2)"/>
            <TextImageComponent
                title= "VIVILO A TU MANERA"
                text= "Humac te permite estar conectado SIEMPRE a la máxima velocidad por FIBRA ÓPTICA."
                src= {ConectividadImg1}
            />
            <ImageTextComponent 
                text= "HUMAC es una empresa líder en provisión de tecnología para barrios cerrados. Pero no tenés que saberlo, tenés que vivirlo. Planificamos y desarrollamos una red de de FIBRA ÓPTICA de última generación en el barrio, potente y segura para que puedas estar conectado SIEMPRE."
                src= {ConectividadImg2}
            />
            <TextImageComponent
                text="Pensamos en cada momento como hacerte más fácil la decisión que tomaste. Estamos detrás de cada servicio digital, de cada aspecto y experiencia de tu nueva elección de vida, poniendo la tecnología de tu lado."
                src= {ConectividadImg3}
            />
            <div className="ventajasCaracteristicas">
                <div className="ventajas-div">
                    <h2 className="ventajasCaracteristicas-h2">SERVICIOS</h2>
                    <ul className="ventajCaract-li">
                        <li>Internet y TV en tu casa</li>
                        <li>WIFI en todo el barrio</li>
                        <li>Fibra óptica e internet </li>
                        <li>Tecnología</li>
                        <li>Servicios digitales</li>
                        <li>Podés trabajar</li>
                        <li>Conexión segura</li>
                        <li>Siempre disponible 24/7</li>
                    </ul>
                </div>
                <div className="caracteristicas-div">
                    <h2 className="ventajasCaracteristicas-h2">VENTAJAS</h2>
                    <ul className="ventajCaract-li">
                        <li >Internet en áreas comunes            </li>
                        <li >Servicios corporativos de conectividad</li>
                        <li >Conectividad en áreas de coworking</li>
                        <li>Conectividad para servicios de seguridad, acceso y sistemas digitales del barrio.</li>
                    </ul>

                </div>
            </div>
            <Reviews1/>
            <NewsPapersLogos/>
            <Footer/>  
        </div>
    )
}
export default Conectividad;