import React, { useEffect, useRef } from "react";
import img1 from "../media/ortronic/e1.png"
import img2 from "../media/ortronic/e2.png"
import img3 from "../media/ortronic/e3.png"
import casas1 from "../media/casas/CASAS1.jpg"
import casas2 from "../media/casas/CASAS2.jpg"
import casas3 from "../media/casas/CASAS3.jpg"
import casas4 from "../media/casas/CASAS4.jpg"
import casas5 from "../media/casas/CASAS5.jpg"
import casas6 from "../media/casas/CASAS6.jpg"
import casas7 from "../media/casas/CASAS7.jpg"
import ofi1 from "../media/oficinas/ofi1.png"
import ofi2 from "../media/oficinas/ofi2.png"
import ofi3 from "../media/oficinas/ofi3.png"
import amenities1 from "../media/amenities/g1.png"
import amenities2 from "../media/amenities/g2.png"
import amenities3 from "../media/amenities/g3.png"
import amenities4 from "../media/amenities/g4.png"
import amenities5 from "../media/amenities/g5.png"
import amenities6 from "../media/amenities/g6.png"
import amenities7 from "../media/amenities/g7.png"
import amenities8 from "../media/amenities/g8.png"
import amenities9 from "../media/amenities/g9.png"
import amenities10 from "../media/amenities/g10.png"
import amenities11 from "../media/amenities/g11.png"
import amenities12 from "../media/amenities/g12.png"
import amenities13 from "../media/amenities/g13.png"
import amenities14 from "../media/amenities/g14.png"
import amenities15 from "../media/amenities/g15.png"
import amenities16 from "../media/amenities/g16.png"
import amenities17 from "../media/amenities/g17.png"
import amenities18 from "../media/amenities/g18.png"
import amenities19 from "../media/amenities/g19.png"
import avance1 from "../media/avancesdeobra/a1.png"
import avance2 from "../media/avancesdeobra/a2.png"
import avance3 from "../media/avancesdeobra/a3.png"
import avance4 from "../media/avancesdeobra/a4.png"
import avance5 from "../media/avancesdeobra/a5.png"
import barrio1 from "../media/barrios/b1.png"
import barrio2 from "../media/barrios/b2.png"
import barrio3 from "../media/barrios/b3.png"
import barrio4 from "../media/barrios/b4.png"
import barrio5 from "../media/barrios/b5.png"
import barrio6 from "../media/barrios/b6.png"
import barrio7 from "../media/barrios/b7.png"
import barrio8 from "../media/barrios/b8.png"
import barrio9 from "../media/barrios/b9.png"
import barrio10 from "../media/barrios/b10.png"
import barrio11 from "../media/barrios/b11.png"
import barrio12 from "../media/barrios/b12.png"
import barrio13 from "../media/barrios/b13.png"
import barrio14 from "../media/barrios/b14.png"
import barrio15 from "../media/barrios/b15.png"
import barrio16 from "../media/barrios/b16.png"
import barrio17 from "../media/barrios/b17.png"
import barrio18 from "../media/barrios/b18.png"
import barrio19 from "../media/barrios/b19.png"
import GaleriaCarousel from "../components/galeriaCarousel";
import "./views.css"
import { Header } from "../components/header/header";
import Reviews1 from "../components/reviews1";
import { NewsPapersLogos } from "../components/newsPapersLogos";
import Footer from "../components/footer/footer";




export const Galeria = () =>{
    const ortronic = [
        {   
            title:"e1",
            src:img1,
            key: 1
        },
        {
            title:"e2",
            src:img2,
            key: 2
        },
        {
            title:"e3",
            src:img3,
            key: 3
        }
    ]
    const casas = [
        {
            title:"casas1",
            src: casas1,
            key: 1
        },
        {
            title:"casas2",
            src: casas2,
            key: 2
        },
        {
            title:"casas3",
            src: casas3,
            key: 3
        },
        {
            title:"casas4",
            src: casas4,
            key: 4
        },
        {
            title:"casas5",
            src: casas5,
            key: 5
        },
        {
            title:"casas6",
            src:casas6,
            key: 6
        },
        {
            title:"casas7",
            src:casas7,
            key: 7
        }
    ]
    const oficinas = [
        {   
            title:"ofi1",
            src: ofi1,
            key: 1
        },
        {
            title:"ofi2",
            src: ofi2,
            key: 2
        },
        {
            title:"ofi3",
            src: ofi3,
            key: 3
        }
    ]
    const amenities = [
        {
            title:"amenities1",
            src: amenities1,
            key: 1
        },
        {
            title:"amenities2",
            src: amenities2,
            key: 2
        },
        {
            title:"amenities3",
            src: amenities3,
            key: 3
        },
        {
            title:"amenities4",
            src: amenities4,
            key: 4
        },
        {
            title:"amenities5",
            src: amenities5,
            key: 5
        },
        {
            title:"amenities6",
            src:amenities6,
            key: 6
        },
        {
            title:"amenities7",
            src: amenities7,
            key: 7
        },
        {
            title:"amenities8",
            src:amenities8,
            key: 8
        },
        {
            title:"amenities9",
            src:amenities9,
            key: 9
        },
        {
            title:"amenities10",
            src: amenities10,
            key: 10
        },
        {
            title:"amenities11",
            src: amenities11,
            key: 11
        },
        {
            title:"amenities12",
            src: amenities12,
            key: 12
        },
        {
            title:"amenities13",
            src: amenities13,
            key: 13
        },
        {
            title:"amenities14",
            src: amenities14,
            key: 14
        },
        {
            title:"amenities15",
            src: amenities15,
            key: 15
        },
        {
            title:"amenities16",
            src:amenities16,
            key: 16
        },
        {
            title:"amenities17",
            src: amenities17,
            key: 17
        },
        {
            title:"amenities18",
            src:amenities18,
            key: 18
        },
        {
            title:"amenities19",
            src:amenities19,
            key: 19
        },
    ]
    const avances = [
        {   
            title:"avances1",
            src: avance1,
            key: 1
        },
        {
            title:"avances2",
            src: avance2,
            key: 2
        },
        {
            title:"avances3",
            src: avance3,
            key: 3
        },
        {
            title:"avances4",
            src: avance4,
            key: 4
        },
        {
            title:"avances5",
            src: avance5,
            key: 5
        }
    ]
    const barrios = [
        {   
            title:"barrio1",
            src: barrio1,
            key: 1
        },
        {   
            title:"barrio2",
            src: barrio2,
            key: 2
        },
        {   
            title:"barrio3",
            src: barrio3,
            key: 3
        },
        {   
            title:"barrio4",
            src: barrio4,
            key: 4
        },
        {   
            title:"barrio5",
            src: barrio5,
            key: 5
        },
        {   
            title:"barrio6",
            src: barrio6,
            key: 6
        },
        {   
            title:"barrio7",
            src: barrio7,
            key: 7
        },
        {   
            title:"barrio8",
            src: barrio8,
            key: 8
        },
        {   
            title:"barrio9",
            src: barrio9,
            key: 9
        },
        {   
            title:"barrio10",
            src: barrio10,
            key: 19
        },
        {   
            title:"barrio11",
            src: barrio11,
            key: 11
        },
        {   
            title:"barrio12",
            src: barrio12,
            key: 12
        },
        {   
            title:"barrio13",
            src: barrio13,
            key: 13
        },
        {   
            title:"barrio14",
            src: barrio14,
            key: 14
        },
        {   
            title:"barrio15",
            src: barrio15,
            key: 15
        },
        {   
            title:"barrio16",
            src: barrio16,
            key: 16
        },
        {   
            title:"barrio17",
            src: barrio17,
            key: 17
        },
        {   
            title:"barrio18",
            src: barrio18,
            key: 18
        },
        {   
            title:"barrio19",
            src: barrio19,
            key: 19
        },
        
    ]
    const animatedElementsRef = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                    observer.unobserve(entry.target);
                }
            });
        });

        animatedElementsRef.current.forEach(element => {
            if (element) {
                observer.observe(element);
            }
        });

        return () => {
            animatedElementsRef.current.forEach(element => {
                if (element) {
                    observer.unobserve(element);
                }
            });
        };
    }, []);

    return(
        <div className="firstContainer">
            <Header backgroundColor="rgba(37,37,37,1)"/>
            <div className="galeria-div-1">
                <div className="galeria-div-2">
                    <h3 className="galeria-h3"ref={el => animatedElementsRef.current.push(el)}>BARRIOS</h3>
                    <GaleriaCarousel style={{width:"100%"}} items={barrios}/>
                </div>
                <div className="galeria-div-2">
                    <h3 className="galeria-h3"ref={el => animatedElementsRef.current.push(el)}>AMENITIES</h3>
                    <GaleriaCarousel style={{width:"100%"}}  items={amenities}/>
                </div>
            </div>
            <div className="galeria-div-1">
                <div className="galeria-div-2">
                    <h3 className="galeria-h3" ref={el => animatedElementsRef.current.push(el)}>AVANCES DE OBRA</h3>
                    <GaleriaCarousel style={{width:"100%"}} items={avances}/>
                </div>
                <div className="galeria-div-2">
                    <h3 className="galeria-h3" ref={el => animatedElementsRef.current.push(el)}>OFICINAS</h3>
                    <GaleriaCarousel style={{width:"100%"}}  items={oficinas}/>
                </div>
            </div>
            <div className="galeria-div-1">
                <div className="galeria-div-2">
                    <h3 className="galeria-h3" ref={el => animatedElementsRef.current.push(el)}>ORTRONIC</h3>
                    <GaleriaCarousel style={{width:"100%"}} items={ortronic}/>
                </div>
                <div className="galeria-div-2">
                    <h3 className="galeria-h3" ref={el => animatedElementsRef.current.push(el)}>CASAS</h3>
                    <GaleriaCarousel style={{width:"100%"}}  items={casas}/>
                </div>
            </div>
            <Reviews1/>
            <NewsPapersLogos/>
            <Footer/>  

        </div>
    )
}

export default Galeria;