import React, { useEffect, useRef } from "react";
import "./components.css"
import { Button } from "reactstrap";


export const TextImageComponent = ({title, text, src, buttonText, href}) => {
    const animatedElementsRef = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                    observer.unobserve(entry.target);
                }
            });
        });

        animatedElementsRef.current.forEach(element => {
            if (element) {
                observer.observe(element);
            }
        });

        return () => {
            animatedElementsRef.current.forEach(element => {
                if (element) {
                    observer.unobserve(element);
                }
            });
        };
    }, []);

    return (
    <div className="textImage-Container">
        <div className="textImage-textContainer" ref={el => animatedElementsRef.current.push(el)}>
            <h1 className="textImage-h1">{title}</h1>
            <h2 className="textImage-h2">{text}</h2>
            {buttonText && (
            <Button outline className="imagetext-button" href={href}>{buttonText}</Button>
          )}
        </div>
        <div className="textImage-imageContainer">
            <img className="textImage-image" alt={title} src={src} ref={el => animatedElementsRef.current.push(el)}></img>
        </div>
    </div>
    )

}