import './App.css';
import React from "react";
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { Home } from './views/Home';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Amenities } from './views/amenities';
import { Avances } from './views/avances';
import {EnergiaSustentable} from './views/energiaSustentable';
import { Conectividad } from './views/conectividad';
import Galeria from './views/galeria';
import Reviews1 from './components/reviews1';
import { NewsPapersLogos } from './components/newsPapersLogos';
import Footer from './components/footer/footer';
import { Prensa } from './views/prensa';
import Barrios from './views/barrios';
import VideoInstitucional from './views/videoInstitucional';
import VentaLotes from './views/ventaLotes';

function App() {
  return (
      
          <Router>
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/amenities" element={<Amenities/>} />
              <Route path="/avances" element={<Avances/>} />
              <Route path="/energiasustentable" element= {<EnergiaSustentable/>} />
              <Route path="/conectividad" element= {<Conectividad/>} />
              <Route path="/galeria" element= {<Galeria/>} />
              <Route path="/prensa" element= {<Prensa/>} />
              <Route path="/barrios" element= {<Barrios/>} />
              <Route path="/video-institucional" element= {<VideoInstitucional/>} />
              <Route path="/ventaLotes" element= {<VentaLotes/>} />
            </Routes>

          </Router>
          
  );
}

export default App;
