import React, { useState } from "react";
import { Carousel, CarouselControl, CarouselItem } from "reactstrap";
import { Header } from "./header/header";
import "./components.css"


export const GaleriaCarousel = ({items, ...args}) =>{
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    
    
    const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    };
  
    const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    };



    const slides = items.map((item) => (
        <CarouselItem 
         className="carousel-fade"
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={item.key}
        >
          <div  >
                <img style={{width:"100%"}} src={item.src} alt={item.title}></img>
          </div>
          </CarouselItem>
    ));

    return(
      
        <div >
          <Carousel 
        className="carousel-fade"
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        {...args}
      >
        {slides}
        <CarouselControl
          className="arrow-prev"
        style={{
            background: "transparent",
            }}
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          className="arrow-next"
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
        </div>
    )
}

export default GaleriaCarousel;