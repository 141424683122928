import React from "react";
import energSustHero from "../media/energSustentableHero.png"
import energSsustImg1 from "../media/energSustentableImg1.jpg"
import energSsustImg2 from "../media/energSustentableImg2.jpg"
import LogoOrtronic from "../media/logoortronic1.png"
import Hero from "../components/hero/hero";
import { TextImageComponent } from "../components/textImageComponent";
import { ImageTextComponent } from "../components/imageTextComponent";
import "./views.css"
import Reviews1 from "../components/reviews1";
import { NewsPapersLogos } from "../components/newsPapersLogos";
import Footer from "../components/footer/footer";

export const EnergiaSustentable = () =>{
 
    const items = [
        {
            src: energSustHero,
            logo: LogoOrtronic
        }
    ]



    return(
    <div className="firstContainer">
        <Hero items = {items} headerBackground = "rgba(0, 0, 0, 0.2)"/>
        <div className="sustentable-div" >
            <h1 className="sustentable-div-h1" >ORTRONIC</h1>
            <h3 className="sustentable-div-h3" >Anticipándonos a nueva matriz energética que se adoptará en todo el planeta.</h3>
        </div>
        <ImageTextComponent 
            text="ORTRONIC, una empresa binacional con 20 años de trayectoria, dedicada a la investigación y al desarrollo de nuevas formas de generación y administración de energía, produce electricidad a partir de fuentes renovables de energía con una alta tasa de eficiencia para hogares y urbanizaciones."
            src={energSsustImg1}
          />
        <TextImageComponent
            text= "Usando energía solar, con ORTRONIC HOME tu hogar siempre tendrá energía disponible, sin estar conectado a la red eléctrica y protegido de cualquier variación de consumo. Podrás en todo momento conocer el estado de tu suministro y optimizar tu consumo con una app."
            src={energSsustImg2}
        />

        <div className="ventajasCaracteristicas">
            <div className="ventajas-div">
                <h2 className="ventajasCaracteristicas-h2">VENTAJAS</h2>
                <ul className="ventajCaract-li">
                    <li>Tenés toda la energía que tu hogar necesita, EN CUALQUIER MOMENTO</li>
                    <li>Sin interrupción del suministro eléctrico y sin factura luz ya que no estás conectado a la red.     </li>
                    <li>Nuestra aplicación móvil asegura que todo esté bajo tu control </li>
                    <li>Ortronic Home es una solución integral, pensada para tu comodidad y confort </li>
                </ul>
            </div>
            <div className="caracteristicas-div">
                 <h2 className="ventajasCaracteristicas-h2">PRINCIPALES CARACTERÍSTICAS</h2>
                 <ul className="ventajCaract-li">
                    <li >Patente propia</li>
                    <li >Innovación</li>
                    <li >Industria nacional</li>
                    <li>La mejor opción para tu hogar</li>
                    <li>Eficiencia</li>
                    <li>Fuentes renovables</li>
                    <li>Energia solar</li>
                    <li>Disfrutá tu casa</li>
                    <li>Cubre las necesidades del hogar</li>
                    <li>Vida sustentable</li>
                    <li>Energia del futuro</li>
                    <li>Gestion propia, simple y amigable</li>
                </ul>

            </div>
        </div>
        <Reviews1/>
            <NewsPapersLogos/>
            <Footer/>  
    </div>
    )
}
export default EnergiaSustentable;