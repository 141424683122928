import React from "react"
import LogoColor from "../../media/logocolor.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube, FaWhatsapp, FaPhone, FaBars} from "react-icons/fa"
import { IoMailOutline} from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import FooterNavbar from "./footerNavbar";
import "./footer.css"
import { Link } from 'react-router-dom';


export const Footer = () =>{



    return (
        <div className="first-container-footer">
            <div className="footer-div1">
                <div className="footer-div1-1">
                    <img className="logocolor" src={LogoColor} alt="logocolor"></img>
                    <div className="social-icons-div">
                       <a href="https://www.facebook.com/TresPinosVilladeCampo"> <FaFacebookF className="social-icons"/></a>
                       <a href="https://www.instagram.com/trespinos_villadecampo/"> <FaInstagram className="social-icons"/></a>
                       <a href="https://www.linkedin.com/company/tres-pinos-villa-de-campo/%20/"><FaLinkedinIn className="social-icons"/></a>
                       <a href="https://www.youtube.com/channel/UCjrVDQFay6lFeihEMArwE5w"><FaYoutube className="social-icons"/></a>
                    </div>
                </div>
                <div className="footer-div1-2">
                <div className="footer-div1-2-dropbtn">
                   <FooterNavbar/>
                </div>
                    <ul className="footer-div1-2-ul">
                        <li className="footer-div1-2-li"><Link className="footer-div1-2-a" to="/barrios">Barrios</Link></li>
                        <li className="footer-div1-2-li"><Link className="footer-div1-2-a"to="/avances">Avances</Link></li>
                        <li className="footer-div1-2-li"><Link className="footer-div1-2-a"  to="/amenities">Amenities</Link></li>
                        <li className="footer-div1-2-li"><Link className="footer-div1-2-a" to="/energiasustentable">Energia Sustentable</Link></li>
                        <li className="footer-div1-2-li"><Link className="footer-div1-2-a" to="/conectividad">Conectividad</Link></li>
                        <li className="footer-div1-2-li"><Link className="footer-div1-2-a" to="/galeria">Galería de Fotos</Link></li>
                        <li className="footer-div1-2-li"><Link className="footer-div1-2-a" to="/video-institucional">VIDEO INSTITUCIONAL</Link></li>
                        <li className="footer-div1-2-li"><Link className="footer-div1-2-a" to="https://mls.kuu.la/share/collection/7JYsR?fs=1&vr=1&sd=1&initload=0&thumbs=1">Tour 360</Link></li>
                    </ul>
                </div>
                <div className="footer-div1-3">
                    <div className="footer-div1-3-divs">
                    <FaWhatsapp className="footer-icons"/>
                    <p className="footer-div1-3-divs-p">Ventas +54 9 2323 529379</p>
                    </div>
                    <div className="footer-div1-3-divs">
                    <FaPhone className="footer-icons"/>
                        <p className="footer-div1-3-divs-p">Administración +54 (0230) 4375050</p>
                    </div>
                    <div className="footer-div1-3-divs">
                    <IoMailOutline className="footer-icons"/>
                        <p className="footer-div1-3-divs-p">contacto@trespinos.com.ar</p>
                    </div>
                    <div className="footer-div1-3-divs">
                    <FaLocationDot  className="footer-icons"/>
                        <p className="footer-div1-3-divs-p">Ruta 6 km. 142, General Rodríguez, Provincia de Buenos Aires, Argentina.</p>
                    </div>
                </div>
            </div>
            
            
            <div className="footer-div2">
                    <p className="footer-div2-p">Tres Pinos Villa de Campo es un emprendimiento de MJM Inversiones Copyright © 2023 | Todos los derechos reservados | <a style={{color:"#4e6f3e", textDecoration:"none"}} href="https://trespinos.com.ar/politicas-de-privacidad/">Políticas de Privacidad</a></p>
            </div>
         </div>
    )
}
export default Footer;