import React, { useState } from "react"

import "./header.css"
import { NavBar } from "./navBar"
import { NavBar2 } from "./navBar2"
import logo from "../../media/logo.png"
import DropdownMenu from "./dropdownMenu"


export const Header = ({backgroundColor}) =>{


    return (
        <>
        <div className="header-container" style={{backgroundColor: backgroundColor}}>
            <div className="header-subcontainer">
            <NavBar/>
            </div>
            <div className="logoNav-Container">
                <div className="div-logo">
                    <img alt="logo" src={logo} className="img-logo"></img>
                </div>
                <div className="navBar2-Container">
                    <NavBar2/>
                </div>
                <div className='dropbtn'>
                    <DropdownMenu/>
                </div>
            </div>
        </div>


        </>

    )
}
export default Header;