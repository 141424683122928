import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  Button,
} from 'reactstrap';
import { Header } from "../header/header";
import "./hero.css"
import { Link } from 'react-router-dom';

function Hero({items, ...args}, headerBackground) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  
  
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = items.map((item) => (
      <CarouselItem 
       className="carousel-fade"
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.key}
      >
        <div className="hero " style={{ backgroundImage: `url(${item.src})` }} >
          <Header backgroundColor={headerBackground}/>
            <div className="textContainer">
            <h2 className="texth2-1">{item.text1}</h2>
            <h2 className="texth2-2">{item.text2}</h2>
            <p className="texth5">{item.description}</p>
            {item.buttonText && (
            <Button outline className="buttonHero"><Link style={{textDecoration: "none", color:"#fff"}} to={item.buttonhref}>{item.buttonText}</Link></Button>
          )}
            </div>
        </div>
        </CarouselItem>

  ));

  return (
    <>
    {items.length > 1 ? (
      <Carousel 
        className="carousel-fade"
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        {...args}
      >
        {slides}
        <CarouselControl
          className="arrow2"
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          className="arrow1"
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    ) : (
      <div className="hero" style={{ backgroundImage: `url(${items[0].src})` }}>
        <Header backgroundColor={headerBackground} />
        <div className="logoContainer">
          <img className="heroLogo" src={items[0].logo} alt="logo">
          </img>
        </div>
      </div>
    )}
  </>
);
}

export default Hero;
