import React, { useEffect, useRef } from "react"
import Hero from "../components/hero/hero"
import Grace from "../media/GraceCabanillas.png"
import "./views.css"
import Reviews1 from "../components/reviews1"
import { NewsPapersLogos } from "../components/newsPapersLogos"
import  Footer from "../components/footer/footer.js"
import HeroImg1 from "../media/homeHeroImg1.jpg";
import HeroImg2 from "../media/homeHeroImg2.jpeg";
import HeroImg3 from "../media/homeHeroImg3.png";
import HeroImg4 from "../media/homeHeroImg4.jpg";
import HeroImg5 from "../media/homeHeroImg5.jpg";
import HeroImg6 from "../media/homeHeroImg6.jpg";
import MapMasterplan1 from "../components/mapMasterplan-1.js"



export const Home = () => {
    const animatedElementsRef = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                    observer.unobserve(entry.target);
                }
            });
        });

        animatedElementsRef.current.forEach(element => {
            if (element) {
                observer.observe(element);
            }
        });

        return () => {
            animatedElementsRef.current.forEach(element => {
                if (element) {
                    observer.unobserve(element);
                }
            });
        };
    }, []);

    const items = [
        {
          src: HeroImg1,
          text1: 'Financiamos tu sueño ',
          text2: 'del lote propio',
          description: 'Estas buscando un crédito para adquirir un terreno? Nuestro desarrollo ofrece la oportunidad de comprar lotes desde 1.000m2 con un adelanto y cuotas. No es necesario recurrir a una entidad bancaria y solicitar un préstamo hipotecario ya que nuestra financiación es directa. Armamos tu plan a medida. Consultanos y hacé realidad el sueño de comprar tu lote!',
          buttonText: 'Enviar Whatsapp',
          buttonhref: 'https://wa.me/5492323529379',
          key: 1,
        },
        {
          src: HeroImg2,
          text1: 'Vive. Ama. Respira ',
          text2: 'Tres Pinos',
          description: 'El proyecto cuenta con 12 barrios cerrados, la etapa 1 con 7 barrios, dos entregados. La etapa 2, incorpora 4 barrios más. En total. son 836 hectáreas. Todos los propietarios podrán disfrutar de una dos lagunas para deportes acuáticos, también cancha de polo, predio de golf, SUM con pileta y piscina semiolímpica, entre otras áreas verdes y espacios recreativos.',
          buttonText: 'Ver Video',
          button2Text: 'Ingresar al Tour 360°',
          buttonhref: '/video-institucional',
          key: 2,
        },
        {
          src: HeroImg3,
          text1: 'Life Style',
          text2: 'Amenities ',
          description: 'Dos piscinas y lagunas para deportes nauticos sin motor y playa pública. Confiteria y cowok. SUM, área de pérgolas, parrillas y juegos para niños. El desarrollo contará con áreas deportivas que incluirá canchas de fútbol, hockey, paddle, tenis y basket así como espacios para actividades ecuestres. Futuras áreas comerciales y colegio Nikola Tesla',
          buttonText: 'Ingresar al Tour 360°',
          buttonhref: 'https://mls.kuu.la/share/collection/7JYsR?fs=1&vr=1&sd=1&initload=0&thumbs=1',
          key: 3,
        },
        {
          src: HeroImg4,
          text1: 'Energía ',
          text2: 'Sustentable',
          description: 'Un mega desarrollo único en Zona Oeste, ubicado en General Rodriguez. Acompañamos una elección de vida que reúne características únicas, entorno verde con imponente laguna, conectividad y sustentabilidad.',
          buttonText: '+ Información',
          buttonhref: '/energia-sustentable',
          key: 4,
        },
        {
          src: HeroImg5,
          text1: 'Conectividad ',
          text2: 'Humac',
          description: 'Un mega desarrollo único en Zona Oeste, ubicado en General Rodriguez. Acompañamos una elección de vida que reúne características únicas, entorno verde con imponente laguna, conectividad y sustentabilidad.',
          buttonText: '+ Información',
          buttonhref: '/conectividad',
          key: 5,
        },
        {
          src: HeroImg6,
          text1: 'Adquirí',
          text2: 'Tu Lote',
          description: 'Un mega desarrollo único en Zona Oeste, ubicado en General Rodriguez. Acompañamos una elección de vida que reúne características únicas, entorno verde con imponente laguna, conectividad y sustentabilidad.',
          buttonText: 'Coordiná tu Visita',
          buttonhref: 'https://api.whatsapp.com/send/?phone=5492323529379&text&type=phone_number&app_absent=0',
          key: 6,
        },
      ];
    return (
        <div className="firstContainer">
        <Hero items = {items}/>
            <div className="graceDesarrollo-div">
                    {/* GRACE DIV */}
                <div className="grace-Container">
                    <div className="grace-textContainer" ref={el => animatedElementsRef.current.push(el)}>
                        <h2 className="grace-h2-1">“Observamos que cada vez más personas buscan un cambio en su calidad de vida y priorizan los entornos naturales, sostenibles y conectados. Este desarrollo crece para acompañar el movimiento natural de la gente desde la ciudad hacia el verde, la naturaleza y los espacios abiertos”</h2>
                        <div className="grace-firma" ref={el => animatedElementsRef.current.push(el)}>
                            <h2 className="grace-h2-2">Grace Cabanillas</h2>
                            <h2 className="grace-h2-3">Gerente de ventas de MJM Inversiones.</h2>
                        </div>
                    </div>
                    <div className="grace-imageContainer">
                        <img className="grace-image" alt="grace" src={Grace} ref={el => animatedElementsRef.current.push(el)}></img>
                    </div>
                </div>
        {/* 
                    DESARROLLO SUSTENTABLE DIV */}
                <div className="desarrollo-div">
                    <div className="desarrollo-titulo-div">
                        UN MEGA DESARROLLO SUSTENTABLE ÚNICO EN ZONA OESTE
                    </div>
                    <div className="desarrollo-textMap-div">
                        <div className="desarrollo-text-div" ref={el => animatedElementsRef.current.push(el)} >
                            GANAR EN CALIDAD DE VIDA ES POSIBLE Y ACCESIBLE
                            <p className="desarrollo-p"><b>¡Inspirá naturaleza!</b>  Tres Pinos Villa de Campo acompaña una elección de vida que reúne características únicas, entorno verde, conectividad y sustentabilidad.</p>
                            <p className="desarrollo-p">Ubicado en General Rodriguez, sobre ruta 6, KM 142 a  10km  del Acceso Oeste, todo sobre autopista. A solo 45 minutos de la ciudad de Buenos Aires.</p>
                            <p className="desarrollo-p">El desarrollo brinda un entorno de 836 hectáreas  junto con diversos amenities y áreas verdes. El proyecto cuenta con 12 barrios cerrados, se podrán disfrutar de dos lagunas para deportes acuáticos sin motor, también cancha de polo, predio de golf, SUM con pileta.</p>
                        </div>
                        <div className="desarrollo-map-div" ref={el => animatedElementsRef.current.push(el)}>
                            <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d26249.55925756179!2d-59.048107!3d-34.67503!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bc897b81a91af1%3A0xa29f2e9aab87a7d5!2sTres%20Pinos%20-%20Villa%20de%20campo!5e0!3m2!1ses-419!2sar!4v1723742552748!5m2!1ses-419!2sar" 
                            width="100%" 
                            height="85%"
                            allowFullScreen="" 
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade">
                            </iframe>
                        </div>
                    </div>
                </div>
            </div> 
            <Reviews1/>
            <NewsPapersLogos/>
            <Footer/>  
         </div>



        
    )
    
}
export default Home;