import React from "react";
import { Header } from "../components/header/header";
import MapMasterplan1 from "../components/mapMasterplan-1";
import "./views.css"
import MapMasterplan2 from "../components/mapMasterplan-2";
import Slider from "react-slick";
import barrios1 from "../media/barrios1.jpg";
import barrios2 from "../media/barrios2.jpg";
import barrios3 from "../media/barrios3.jpg";
import barrios4 from "../media/barrios4.jpg";
import barrios5 from "../media/barrios5.jpg";
import barrios6 from "../media/barrios6.jpg";
import barrios8 from "../media/barrios8.jpg";
import barrios9 from "../media/barrios9.jpg";
import barrios10 from "../media/barrios10.jpg";
import barrios11 from "../media/barrios11.jpg";
import barrios12 from "../media/barrios12.jpg";
import barrios13 from "../media/barrios13.jpg";
import Reviews1 from "../components/reviews1";
import { NewsPapersLogos } from "../components/newsPapersLogos";
import Footer from "../components/footer/footer";
import masterplan2 from "../media/masterplan2-nuevo.png"

export const Barrios = () =>{

    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
          autoplay: true,
          autoplaySpeed: 2000,   
          speed: 500,

      };
const items = [
    {
        key: "1",
        src: barrios1,
        alt: "barrio"
    },
    {       
        key: "2",
        src: barrios2,
        alt: "barrio"
    },
    {       
        key: "3",
        src: barrios3,
        alt: "barrio"
    },
    {       
        key: "4",
        src: barrios4,
        alt: "barrio"
    },
    {       
        key: "5",
        src: barrios5,
        alt: "barrio"
    },
    {       
        key: "6",
        src: barrios6,
        alt: "barrio"
    },
    {       
        key: "8",
        src: barrios8,
        alt: "barrio"
    },
    {       
        key: "9",
        src: barrios9,
        alt: "barrio"
    },
    {       
        key: "10",
        src: barrios10,
        alt: "barrio"
    },
    {       
        key: "11",
        src: barrios11,
        alt: "barrio"
    },
    {       
        key: "12",
        src: barrios12,
        alt: "barrio"
    },
    {       
        key: "13",
        src: barrios13,
        alt: "barrio"
    },

];
    return (
        <div className="firstContainer">
            <Header backgroundColor="rgba(37,37,37,1)"/>
            <div className="masterplan-div">
                <h1 className="masterplan-h1">MASTERPLAN ETAPA 1</h1>
                <p className="masterplan-p">La etapa 1 con 7 barrios, dos entregados. La etapa 2, incorpora 5 barrios más. En total. son 836 hectáreas. Todos los propietarios podrán disfrutar de dos lagunas para deportes acuáticos, también cancha de polo, predio de golf, SUM con pileta y piscina semiolímpica, entre otras áreas verdes y espacios recreativos.</p>
                <div className="masterplan-mapdiv">
                <MapMasterplan1/>
                </div>
                <a href="/masterplanetapa1.pdf"
                   target="_blank"
                   rel="noopener noreferrer" 
                   className="masterplan-a">DESCARGAR MASTERPLAN ETAPA 1</a>
            </div>
            <div className="masterplan-div">
                <h1 className="masterplan-h1">MASTERPLAN ETAPA 2</h1>
                <div className="masterplan-mapdiv" style={{display:"flex", justifyContent:"center"}}>
                {/* <MapMasterplan2/> */}
                <img src={masterplan2} style={{width:"90%", height:"auto", marginBottom:"30px"}} alt="masterplan2"></img>
                </div>
                <a href="/masterplanetapa2-nuevo.pdf"
                   target="_blank"
                   rel="noopener noreferrer"
                className="masterplan-a">DESCARGAR MASTERPLAN ETAPA 2</a>
            </div>
            <div className="masterplan-gal-div">
            <h1 className="masterplan-gal-h1">GALERÍA</h1>
            <Slider {...settings}>
                {items.map((item)=>(
                    <div className="">
                    <img key={item.key} style={{width: "95%", height:"auto"}} src={item.src} alt={item.alt}/>
                    </div>
                    ))}
            </Slider>
            </div>
            <Reviews1/>
            <NewsPapersLogos/>
            <Footer/>  
        </div>

    );
} 
export default Barrios;